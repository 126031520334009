<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" style="margin-top: 30px;" @finish="onSearch">
				<a-row>
<!--					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">-->
<!--						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="(id) => getAllCinemaList(id, true)">-->
<!--							&lt;!&ndash; <a-select-option :value="0">全部</a-select-option> &ndash;&gt;-->
<!--							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->

<!--					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">-->
<!--						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">-->
<!--							<a-select-option :value="0">全部</a-select-option>-->
<!--							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->
          <a-form-item label="是否一券通" name="isOneVoucher" class="ui-form__item">
            <a-select v-model:value="formState.isOneVoucher" style="width: 180px;" placeholder="请选择状态">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="ui-form__item" name="useCinemaId" label="可用影院">
            <a-select placeholder="请选择" v-model:value="formState.useCinemaId" style="width: 250px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
				</a-row>
				
				<a-row v-show="showAll">

					<a-form-item label="批次号" name="batchNo" class="ui-form__item">
						<a-input v-model:value="formState.batchNo" placeholder="请输入批次号"></a-input>
					</a-form-item>

					<a-form-item label="批次号名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入批次号名称"></a-input>
					</a-form-item>
					
					<a-form-item label="卖品劵号" name="barcode" class="ui-form__item">
						<a-input v-model:value="formState.barcode" placeholder="请输入卖品劵号"></a-input>
					</a-form-item>
					
					<a-form-item label="绑定状态" name="isBind" class="ui-form__item">
						<a-select v-model:value="formState.isBind" style="width: 180px;" placeholder="请选择绑定状态">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已绑定</a-select-option>
							<a-select-option :value="0">未绑定</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="使用状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" style="width: 180px;" placeholder="请选择使用状态">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">未兑换</a-select-option>
							<a-select-option :value="2">已兑换</a-select-option>
							<a-select-option :value="3">已过期</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="是否启用" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" style="width: 180px;" placeholder="请选择绑定状态">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="有效期开始时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>

					<a-form-item label="有效期结束时间" class="ui-form__item">
						<a-range-picker v-model:value="time1"></a-range-picker>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['coupon_snack_record_add']" type="primary" style="margin-right: 10px;" @click="onAdd">新增卖品券</a-button>
						<!-- <a-button type="primary">新增卖品券记录</a-button> -->
						<a-button v-permission="['coupon_snack_list_bind']" type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onBatchBind">批量绑定</a-button>
						<a-button v-permission="['coupon_snack_list_delay']" type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onBatchDelay">批量延期</a-button>
						<a-button v-permission="['coupon_snack_list_open']" type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onShowTaskModal('enableSaleCoupon')">批量启用</a-button>
						<a-button v-permission="['coupon_snack_list_disabled']" type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onShowTaskModal('disableSaleCoupon')">批量禁用</a-button>
						<a-button v-permission="['coupon_snack_list_batchDelete']" danger :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onBatchDeleteCoupon">批量删除</a-button>
						<span v-permission="['coupon_snack_list_import']">
							<ExportReport type="snackCouponsList" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :rowSelection="{ type: 'checkbox', getCheckboxProps: getCheckboxProps, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }" :scroll="{ x: 1900 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time'">
							<div v-if="record.validPeriodType === 7">
								领取后{{ record.period }}天内有效
							</div>
							<div v-else>
								{{ transDateTime(record.receiveStartTime, 1) }} ~ {{ transDateTime(record.receiveEndTime, 1) }}
							</div>
						</template>
						<template v-if="column.key === 'barcode'">
							<span :id="'copyed' + index">{{ record.barcode }}</span>
						</template>
						<template v-if="column.key === 'status'">
							<span>{{ record.isBind === 1 ? '已绑定' : '未绑定' }}</span>
						</template>
						<template v-if="column.key === 'isIssue'">
							<span>{{ record.isIssue === 1 ? '已发放' : '未发放' }}</span>
						</template>
						<template v-if="column.key === 'userCouponStatus'">
							{{ ['--', '正常', '已兑换', '已过期', '未激活'][record.userCouponStatus] }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'isGrant'">
							{{ record.isGrant ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
            <template v-if="column.key === 'isOneVoucher'">
              {{ record.isOneVoucher ? '是' : '否' }}
            </template>
            <template v-if="column.key === 'cinemaNames'">
              {{ record.isOneVoucher == 1 ? '全部影院' : record.cinemaNames }}
            </template>

						
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
											<a-menu-item>启用</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_disabled']" v-else @click="onDisabled(record)">
											<a-menu-item>禁用</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_bind']" v-if="record.isBind !== 1 && !record.isGrant" @click="onBind(record)">
											<a-menu-item>
												绑定
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_issue']" v-if="record.isIssue !== 1 && !record.isGrant" @click="onUpdateIssue(record, index)">
											<a-menu-item>
												发放
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="visible" destroyOnClose title="已选" width="850px" :zIndex="8" @ok="onBindOk">
			<!-- <a-button style="margin-bottom: 20px;" @click="onSetVisible">输入会员账号</a-button> -->
			<a-spin :spinning="loading">
				<a-form ref="setMemberForm" name="setMemberForm" :model="memberModelRef">
          <a-form-item label="发放影院" name="issueCinema" :rules="[{required: true, message: '必填项不允许为空'}]">
            <a-select placeholder="请选择" v-model:value="memberModelRef.issueCinema">
              <a-select-option v-for="item in issueCinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="会员手机号" :rules="[{required: true, message: '请输入会员手机号'}]">
						<a-input v-model:value="memberModelRef.phone" placeholder="请输入会员手机号"/>
					</a-form-item>
				</a-form>
				
				<a-table :dataSource="selectedListData" :columns="selectedColumns" :pagination="false">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.receiveStartTime, 1) }} ~ {{ transDateTime(record.receiveEndTime, 1) }}
						</template>
						<template v-if="column.key === 'status'">
							<span>{{ record.isBind === 1 ? '已绑定' : '未绑定' }}</span>
						</template>
					</template>
				</a-table>
			</a-spin>
		</a-modal>
		
		<a-modal v-model:visible="setVisible" destroyOnClose width="700px" title="绑定会员" @ok="onMemberOk">
			<a-form ref="setMemberForm" name="setMemberForm" :model="memberModelRef" :labelCol="{span: 6}" :wrapperCol="{span: 14}">
				<a-form-item v-if="!visible" label="兑换券号">
					{{ bindItem.barcode }}
				</a-form-item>
				
				<a-form-item v-if="!visible" label="批次号">
					{{ bindItem.batchNo }}
				</a-form-item>
				
				<a-form-item v-if="!visible" label="有效期">
					{{ transDateTime(bindItem.receiveStartTime, 1) }} - {{ transDateTime(bindItem.receiveEndTime, 1) }}
				</a-form-item>
        <a-form-item v-if="!visible" label="发放影院" name="issueCinema" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-select placeholder="请选择" v-model:value="memberModelRef.issueCinema" style="width: 250px;">
            <a-select-option v-for="item in issueCinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
				<a-form-item label="会员手机号" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="memberModelRef.phone" placeholder="请输入会员手机号"/>
				</a-form-item>
			</a-form>
		</a-modal>
		
		<a-modal v-model:visible="seeVisible" title="查看" width="750px">
			<a-form :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
				<a-form-item label="卖品券号">{{ detail.barcode }}</a-form-item>
				<a-form-item label="批次号">{{ detail.batchNo }}</a-form-item>
				<a-form-item label="有效期">
					{{ transDateTime(detail.receiveStartTime, 1) }} - {{ transDateTime(detail.receiveEndTime, 1) }}
				</a-form-item>
				<a-form-item label="所属影院">
					{{ detail.cinemaName }}
				</a-form-item>
				<a-form-item label="使用规则">
					{{ detail.useRule }}
				</a-form-item>
				<a-form-item label="可兑换卖品">
					只可兑换其中一件
					<a-table :columns="snackClumns" :dataSource="snackList" :pagination="false">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'img'">
								<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
							</template>
							
							<template v-if="column.key === 'card'">
								<div v-if="record.isCard">
									<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
									<div>卡次数：{{ record.cardBalanceTimes }}</div>
									<div>卡等级：{{ record.cardTitle || '' }}</div>
								</div>
								<div v-else>--</div>
							</template>
						</template>
					</a-table>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="delayVisible" title="已选" width="850px" destroyOnClose @ok="onBatchDelayOk" :zIndex="9"
				 @cancel="onBatchCancel">
			<a-spin :spinning="loading">
				<a-button style="margin-bottom: 20px;" @click="onDelay(null)">设置延期时间</a-button>

				<a-table :dataSource="selectedListData" :columns="selectedDelayColumns" :pagination="false">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'endTime'">
							{{ transDateTime(record.receiveEndTime, 1) }}
						</template>
						<template v-else-if="column.key === 'newEndTime'">
							<span
									v-if="delayModelRef.setValue && !setDelayVisible">{{ transDateTime(delayEndTime(record.receiveEndTime), 1) }}</span>
						</template>
					</template>
				</a-table>
			</a-spin>
		</a-modal>
		<a-modal v-model:visible="setDelayVisible" width="800px" destroyOnClose title="卖品券延期" @ok="onDelayOk"
				 @cancel="onDelayCancel">
			<a-spin :spinning="loading">
				<a-form ref="delayForm" name="delayForm" :model="delayModelRef" :labelCol="{span: 5}"
						:wrapperCol="{span: 16}">
					<a-form-item label="快捷选择" name="setValue" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-radio-group v-model:value="delayModelRef.setValue" button-style="solid">
							<a-radio-button :value="1">1天</a-radio-button>
							<a-radio-button :value="2">7天</a-radio-button>
							<a-radio-button :value="3">3月</a-radio-button>
							<a-radio-button :value="4">6月</a-radio-button>
							<a-radio-button :value="5">1年</a-radio-button>
							<a-radio-button :value="6">自定义日期</a-radio-button>
							<a-radio-button :value="7">自定义时间</a-radio-button>
						</a-radio-group>
					</a-form-item>

					<div>
						<a-form-item v-if="delayModelRef.setValue === 6" label="延期至" name="specifiesTime"
									 :rules="[{required: true, message: '必选项不允许为空'}]">
							<a-date-picker :disabled-date="disabledDate" v-model:value="delayModelRef.specifiesTime" />
							<p style="margin-top: 6px;color: #666;">需大于今天才会生效</p>
						</a-form-item>
						<a-form-item v-else-if="delayModelRef.setValue === 7" label="延期至" name="delayDays"
									 :rules="[{required: true, message: '必选项不允许为空'}]">
							<a-input-number v-model:value="delayModelRef.delayDays" :min="0" :precision="0"
											placeholder="请输入"></a-input-number>
							<span style="padding-left: 8px;">天</span>
						</a-form-item>
						<!-- <a-form-item v-else-if="!visible" label="延期至">
							<span>{{ transDateTime(delayEndTime(setRecordData.endTime), 1) }}</span>
						</a-form-item> -->
					</div>
				</a-form>
			</a-spin>
		</a-modal>
		<a-modal v-model:visible="taskVisible" title="创建批量操作任务" @ok="onCreateTaskDelayOk" :zIndex="10" destroyOnClose>
			<a-spin :spinning="loading">
				<a-form>
					<a-form-item label="任务名称">
						<a-input v-model:value="taskTitle" placeholder="请输入"/>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
	<temp v-else @back="onBack"></temp>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getExchangeList, updateIssue, bindVoucher, unbundlingExchangeInfo, batchBindVoucher, delCoupon, couponBatchDetail, updateIsDisabled, asyncBatchEnable, asyncBatchDisable, batchDelaySnackCoupon, batchDelCoupon } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			temp,
			ExportReport
		},
		data() {
			return {
				loading: false,
				showAll: false,
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					couponType: 3,
					isBind: '',
					status: '',
          isOneVoucher:'',
          useCinemaId: '',
				},
        issueCinema:'',
        issueCinemaList:[],
				time: [],
				time1: [],
				searchData: {},
				organizationList: [],
				cinemaAllList: [],
				detail: {},
				seeVisible: false,
				visible: false,
				setVisible: false,
				selectedRowKeys: [],
				selectedListData: [],
				selectedColumns: [
        //     {
				// 	title: '所属影院',
				// 	dataIndex: 'cinemaName'
				// },
          {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '卖品券号',
					dataIndex: 'barcode'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '绑定状态',
					key: 'status'
				}],
				bindItem: {},
				memberModelRef: {
					member: '',
          issueCinema:''
				},
				list: [],
				columns: [
        //   {
				// 	title: '所属影院',
				// 	dataIndex: 'cinemaName'
				// },
          {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '卖品券号',
					key: 'barcode',
					dataIndex: 'barcode'
				}, {
					title: '有效期',
					key: 'time',
					width: 210
				},{
          title: '是否一券通',
          key: 'isOneVoucher',
        },{
          title: '可用影院',
          key: 'cinemaNames',
          width: 200,
        },{
					title: '绑定状态',
					key: 'status',
					width: 130
				}, {
					title: '发放状态',
					key: 'isIssue',
					width: 130
				}, {
					title: '使用状态',
					key: 'userCouponStatus',
				}, {
					title: '是否启用',
					key: 'isDisabled',
					width: 130
				}, {
					title: '是否商品卖品发放',
					key: 'isGrant'
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 190
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 160
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					pageSizeOptions: ['10','20', '50', '100', '500'],
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				snackList: [],
				snackClumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'img'
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '销售价',
					dataIndex: 'nowPrice'
				}],
				showModal: false,
				delayVisible: false,
				setDelayVisible: false,
				delayModelRef: {},
				selectedDelayColumns: [{
					title: '影院',
					dataIndex: 'cinemaName'
				}, {
					title: '卖品券号',
					dataIndex: 'barcode'
				}, {
					title: '延期前有效期',
					key: 'endTime'
				}, {
					title: '延期后有效期',
					key: 'newEndTime'
				}],
				taskVisible: false,
				taskTitle: '',
				taskType: ''
			}
		},
		created() {
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (this.isRef) {
					this.getData();
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				// this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
        //去掉organizationId
        delete this.searchData.organizationId
        this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.status = this.searchData.status !== '' ? this.searchData.status : undefined;
				this.searchData.isBind = this.searchData.isBind !== '' ? this.searchData.isBind : undefined;
        this.searchData.isOneVoucher = this.searchData.isOneVoucher !== '' ? this.searchData.isOneVoucher : undefined;
        this.searchData.useCinemaId = this.searchData.useCinemaId !== '' ? this.searchData.useCinemaId : undefined;
        if (this.time && this.time.length) {
					this.searchData.validStartTime = this.time[0].startOf('day').unix();
					this.searchData.validEndTime = this.time[1].endOf('day').unix();
				}
				if (this.time1 && this.time1.length) {
					this.searchData.failureStartTime = this.time1[0].startOf('day').unix();
					this.searchData.failureEndTime = this.time1[1].endOf('day').unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.time = [];
				this.time1 = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getExchangeList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						couponType: 3,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}

			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async onUpdateIssue(item, index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copyed" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
				item.isIssue = 1;
				// this.loading = true;
				let ret = await updateIssue({
					id: item.id
				});
				// this.loading = false;
				// if (ret.code === 200) {
					
				// }
			},
			onAdd() {
				this.showModal = true;
			},
			async onSee(item) {
				this.loading = true;
				try {
					let ret = await couponBatchDetail({
						id: item.voucherImportId
					})
					this.loading = false;
					if (ret.code === 200) {
						this.detail = item;
						this.snackList = ret.data.storeGoodsStocks;
						this.seeVisible = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			getCheckboxProps(record) {
				return { disabled: record.userCouponStatus === 2, id: record.id.toString() }
			},
			onSelectChange(record, selected) {
				if (selected) {
					if (this.selectedRowKeys.length < 500 && record.userCouponStatus !== 2) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.$message.warn('最大可选择数据不允许大于500条');
					}
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					let showWain = true;
					this.list.forEach(item => {
						if (this.selectedRowKeys.length < 500) {
							if (this.selectedRowKeys.indexOf(item.id) === -1 && item.userCouponStatus !== 2) {
								this.selectedListData.push(JSON.parse(JSON.stringify(item)));
								this.selectedRowKeys.push(item.id);
							}
						} else {
							if (showWain) {
								showWain = false;
								this.$message.warn('选择数据不允许大于500条');
								return;
							}
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onBatchDisabled() {
				this.$confirm({
					title: "提示",
					content: '确定批量禁用选择的卖品券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await asyncBatchDisable({
								ids: this.selectedRowKeys.join(','),
								type: this.taskType,
								title: this.taskTitle
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.taskVisible = false;
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			},
			onBatchOpen() {
				this.$confirm({
					title: "提示",
					content: '确定批量启用选择的卖品券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await asyncBatchEnable({
								ids: this.selectedRowKeys.join(','),
								type: this.taskType,
								title: this.taskTitle
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.taskVisible = false;
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			},
			onBatchBind() {
				let canBatchBind = true;
				for (let i = 0; i < this.selectedListData.length;i++) {
					if (this.selectedListData[i].isBind || this.selectedListData[i].isGrant) {
						canBatchBind = false;
						break;
					}
				}
				if (!canBatchBind) {
					return this.$message.warn('已选择的卖品券中含有【已绑定】或【用于商品卖品发放】的卖品券，无法进行批量绑定操作');
				}
        const newListData = this.getCommonCinemaIds(this.selectedListData);
        this.issueCinemaList = this.cinemaAllList.filter(item => newListData.includes(String(item.id)))
				this.memberModelRef = {};
				this.visible = true;
        console.log(this.selectedListData,"selectedListData")

			},
      getCommonCinemaIds(selectedListData) {
        // 将字符串转换成数组，并且将每个字符串的cinemaIds转换成数组
        const cinemaIdsArray = selectedListData.map(item => item.cinemaIds.split(','));

        // 获取数组的交集（即相同部分）
        const commonCinemaIds = cinemaIdsArray.reduce((acc, curr) => {
          return acc.filter(id => curr.includes(id));
        });

        return commonCinemaIds;
      },
			onBind(item) {
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.memberModelRef = {};
				this.bindItem = JSON.parse(JSON.stringify(item));
        let cinemaList = item.cinemaIds.split(',')
        this.issueCinemaList = this.cinemaAllList.filter(item => cinemaList.includes(String(item.id)))
				this.setVisible = true;
			},
			onMemberOk() {
				//  单一绑定
				this.$refs.setMemberForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await bindVoucher({
							id: this.bindItem.id,
							phone: this.memberModelRef.phone,
              cinemaId:this.memberModelRef.issueCinema
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('绑定成功');
							this.memberModelRef = {};
							this.setVisible = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async onBindOk() {
				// 批量绑定
				this.$refs.setMemberForm.validateFields().then(async () => {
					this.taskType = 'bindCoupon';
					this.taskTitle = '';
					this.taskVisible = true;
				})
			},
			onBatchBindCoupon() {
				this.$confirm({
					title: '提示',
					content: '确定批量绑定选择的卖品券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await batchBindVoucher({
								ids: this.selectedRowKeys.join(','),
								phone: this.memberModelRef.phone,
								title: this.taskTitle,
								type: 'snackBindUserCoupon',
                cinemaId:this.memberModelRef.issueCinema
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('批量绑定任务创建成功，已选中的兑换券将在2-3分钟后完成绑定');
								this.memberModelRef = {};
								this.visible = false;
								this.taskVisible = false;
								this.taskTitle = '';
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该卖品券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updateIsDisabled({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该卖品券吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await delCoupon({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
							console.log(e)
						}
					}
				})
			},
			delayEndTime(expireDate) {
				//  延期时间处理
				if (!expireDate) return '';
				if ((expireDate + '').length === 10) {
					expireDate = expireDate * 1000;
				}
				if (this.delayModelRef.setValue === 1) {
					return this.moment(expireDate).add(1, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 2) {
					return this.moment(expireDate).add(7, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 3) {
					return this.moment(expireDate).add(3, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 4) {
					return this.moment(expireDate).add(6, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 5) {
					return this.moment(expireDate).add(12, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 6) {
					return this.delayModelRef.specifiesTime.endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 7) {
					if (this.delayModelRef.delayDays) {
						return this.moment(expireDate).add(this.delayModelRef.delayDays, 'day').endOf('day').valueOf();
					} else {
						return this.moment(expireDate).endOf('day').valueOf();
					}
				} else {
					return this.moment(expireDate).endOf('day').valueOf();
				}
			},
			onDelay(item) {
				if (item) {
					this.selectedRowKeys = [];
					this.selectedListData = [];
				}
				this.setRecordData = item;
				this.setDelayVisible = !this.setDelayVisible;
			},
			onBatchDelay() {
				this.delayVisible = true;
			},
			onDelayCancel() {
				if (!this.visible) {
					this.delayModelRef = {};
				}
				this.setDelayVisible = false;
			},
			disabledDate(current) {
				return current && current < this.moment().endOf('day');
			},
			onDelayOk() {
				// 延期
				this.$refs.delayForm.validateFields().then(async () => {
					if (!this.delayVisible) {
						this.loading = true;
						try {
							let ret = await delayExchange({
								id: this.setRecordData.id,
								delayTime: parseInt(this.delayEndTime(this.setRecordData.endTime) /
									1000)
							});
							this.loading = false;
							if (ret.code === 200) {
								this.setRecordData = {};
								this.$message.success('批量延期操作成功，已选中的兑换券将在2-3分钟后完成延期');
								this.setDelayVisible = false;
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					} else {
						this.setDelayVisible = false;
					}
				})
			},
			onBatchCancel() {
				this.delayModelRef = {};
				this.visible = false;
			},
			async onBatchDelayOk() {
				// 批量延期
				if (!this.delayModelRef.setValue) {
					return this.$message.warn('请设置延期时间');
				} else {
					this.taskType = '';
					this.taskTitle = '';
					this.taskVisible = true;
				}
			},
			onShowTaskModal(type) {
				this.taskType = type;
				this.taskTitle = '';
				this.taskVisible = true;
			},
			async onCreateTaskDelayOk() {
				// 执行批量延期
				if (!this.taskTitle.trim()) {
					this.$message.warn('请输入任务名称');
			        return;
				}
				if (this.taskType === '') {
					// 批量延期
					this.asyncBatchDelayCoupon();
				}
				if (this.taskType === 'enableSaleCoupon') {
					// 批量启用
					this.onBatchOpen();
				}
				if (this.taskType === 'disableSaleCoupon') {
					// 批量禁用
					this.onBatchDisabled();
				}
				if (this.taskType === 'bindCoupon') {
					this.onBatchBindCoupon();
				}
				if (this.taskType == 'batchDelete') {
					this.onBatchDeleteCoupon();
				}
			},
			async onBatchDeleteCoupon() {
				let postData = [];
				let filterList = [];
				this.selectedListData.forEach(item => {
					if (item.isBind !== 1) {
						postData.push(item.id);
					} else {
						filterList.push(item.id);
					}
				})
				if (filterList.length) {
					this.$confirm({
						width: 580,
						title: '提示',
						content: '已选择的券中含有【已绑定】用户的券，无法删除，您可以',
						okText: '剔除【已绑定】用户的券后删除',
						cancelText: '取消删除',
						onOk:() => {
							if (postData.length) {
								this.batchDelete(postData)
							} else {
								this.$message.warn('已选择的券中没有可以删除的券');
							}
						}
					})
				} else {
					this.$confirm({
						title: '提示',
						content: '确定删除已选择的券吗？',
						onOk:() => {
							this.batchDelete(postData)
						}
					})
				}
			},
			async batchDelete(ids) {
				this.loading = true;
				try {
					let ret = await batchDelCoupon({
						ids: ids.join(',')
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('批量删除成功');
						this.selectedListData = [];
						this.selectedRowKeys = [];
						this.taskVisible = false;
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async asyncBatchDelayCoupon() {
				let postData = this.selectedListData.map(item => {
					return {
						id: item.id,
						delayTime: parseInt(this.delayEndTime(item.receiveEndTime) / 1000)
					}
				})
				this.loading = true;
				try {
					let ret = await batchDelaySnackCoupon({
						appCoupons: postData,
						title: this.taskTitle
					});
					this.loading = false;
					if (ret.code === 200) {
						this.delayModelRef = {};
						this.selectedRowKeys = [];
						this.selectedListData = [];
						this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
						this.delayVisible = false;
						this.taskVisible = false;
						this.taskTitle = '';
						this.getData();
					}
				} catch (e) {
					console.log(e)
					this.loading = false;
					this.taskVisible = false;
					this.taskTitle = '';
				}
			},
      // onChangeIsOne(value){
      //   if (value == ''){
      //     this.formState.useCinemaId = ''
      //   }
      //
      // }
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
